import React from "react"
import { openPopupWidget } from "react-calendly"

const CalendlyButton = props => {
  const { styles, url } = props
  const onClick = () => openPopupWidget({ url })

  return (
    <div style={styles} onClick={onClick}>
      {props.children}
    </div>
  )
}

export default CalendlyButton