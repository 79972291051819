import React from "react"

import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link } from "gatsby"
import { inject, observer } from "mobx-react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import financeProductData_en from "../../content/en_financeProduct"
import financeProductData_de from "../../content/de_financeProduct.json"
import landingPageData_de from "../../content/de_landingPage.json"
import landingPageData_en from "../../content/en_landingPage.json"



import CalendlyButton from "../components/CalendlyButton"
import styles from "../components/styles"
import colors from "../components/colors"
import seo from "../../content/seo.json"
import Newsletter from "../components/Newsletter"
import ContactBanner from "../components/ContactBanner"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device: "desktop",
      email: "",
    }
  }
  async componentDidMount() {
    this.handleResize()

    if (typeof window !== "undefined")
      window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    if (typeof window !== "undefined")
      window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    if (
      document.documentElement.clientWidth > 599 &&
      this.state.device !== "desktop"
    ) {
      this.setState(
        {
          device: "desktop",
        } /*, () => {
        if (window.MediumWidget) {
          window.MediumWidget.unmount()
          this.scriptLoaded()
        }
      }*/
      )
    }
    if (
      document.documentElement.clientWidth < 600 &&
      this.state.device !== "mobile"
    ) {
      this.setState({ device: "mobile" })
    }
  }

  handleSubscription = async event => {
    event.preventDefault()
    try {
      let res = await addToMailchimp(this.state.email)

      if (res.result === "success") {
        alert(res.msg)
      } else {
        alert(res.msg)
      }
    } catch (err) {
      console.log("error")
      console.log(err)
    }
  }
  render() {
    let selectedStyles =
      this.props.store.device === "mobile" ? styles.mobile : styles.desktop
    let landingPageData =
      this.props.store.language === "en"
        ? landingPageData_en
        : landingPageData_de

    let financeProductData =
      this.props.store.language === "en"
        ? financeProductData_en
        : financeProductData_de
    const calendlyLink = "https://calendly.com/lt-finance-platform/lt-finance-platform-exploration"
    return (
      <Layout data={landingPageData} device={this.state.device}>
        <Helmet>
          <title>{'LT | '+financeProductData_en.section1Heading}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta charSet="utf-8" />
          <html lang={this.props.store.language} />
          <meta
            property="og:image"
            content={financeProductData_en.socialSharingVisual}
          />
          <meta
            property="og:title"
            content={financeProductData_en.socialSharingTitle}
          />
          <meta charSet="utf-8" />
          <meta name="twitter:card" content={"summary_large_image"} />
          <meta name="twitter:site" content="@liquiditeam" />
          <meta
            name="twitter:title"
            content={financeProductData_en.socialSharingTitle}
          />
          <meta
            name="twitter:description"
            content={financeProductData_en.socialSharingDescription}
          />
          <meta
            name="twitter:image"
            content={
              "https://liquidi.team" + financeProductData_en.socialSharingVisual
            }
          />
        </Helmet>
        <div
          style={{
            backgroundColor: colors.lightgrey,
            position: "relative",
            zIndex: 1,
          }}
        >
          <img
            src={financeProductData.section1Image}
            style={selectedStyles.index.upperSection.img}
            alt="visitors of football stadium"
          />
          <div style={selectedStyles.index.upperSection.container}>
            <div style={selectedStyles.index.upperSection.polygon}>
              <div
                style={selectedStyles.index.section1.polygonTextContainer}
              >
                <h1 style={selectedStyles.index.upperSection.heading}>
                  {financeProductData.section1Heading}
                </h1>
                <div style={selectedStyles.index.upperSection.text}>
                  <ReactMarkdown
                    escapeHtml={false}
                    parserOptions={{ commonmark: true }}
                    source={financeProductData.section1Text}
                  />
                </div>
                <CalendlyButton
                  styles={selectedStyles.index.upperSection.cta}
                  url={
                    calendlyLink
                  }
                >
                  {financeProductData.section1ctaText}
                </CalendlyButton>
              </div>
            </div>
          </div>

          <div style={selectedStyles.index.upperSection.goldFeatureContainer}>
            <div style={selectedStyles.index.upperSection.goldFeature}></div>
          </div>
          <div style={selectedStyles.index.middleSection.container}>
            {this.props.store.device === "mobile" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "50px 0px 0px",
                  width: "100%",
                }}
              >
                <CalendlyButton
                  styles={selectedStyles.index.upperSection.cta}
                  url={
                    calendlyLink
                  }
                >
                  {financeProductData.section2ctaText}
                </CalendlyButton>
              </div>
            )}
            <div style={selectedStyles.index.middleSection.imgContainer}>
              <img
                src={financeProductData.section2Image}
                style={selectedStyles.index.middleSection.img}
                alt="some graphic"
              />
            </div>
            <div style={selectedStyles.index.middleSection.textContainer}>
              <div
                style={selectedStyles.index.middleSection.innerTextContainer}
              >
                <h3 style={selectedStyles.index.middleSection.heading}>
                  {financeProductData.section2Heading}
                </h3>
                <h2 style={selectedStyles.index.middleSection.subHeading}>
                  {financeProductData.section2SubHeading}
                </h2>
                <div style={selectedStyles.index.middleSection.text}>
                  <ReactMarkdown
                    escapeHtml={false}
                    parserOptions={{ commonmark: true }}
                    source={financeProductData.section2Text}
                  />
                </div>
                {this.props.store.device === "desktop" && (
                  <div style={{ padding: "50px 0px 0px" }}>
                  <CalendlyButton
                  styles={selectedStyles.index.middleSection.cta}
                  url={
                    "https://calendly.com/lt-finance-platform/lt-finance-platform-exploration"
                  }
                >
                  {financeProductData.section2ctaText}
                </CalendlyButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={selectedStyles.index.lowerSection.upperBackground}></div>
          <div
            style={{
              height: "9.9vw",
              marginTop: "-10vw",
              width: "100%",
              backgroundColor: colors.darkblue,
              clipPath: "polygon(0% 100%, 7% 50%, 93% 50%, 100% -1%, 0% -1%)",
              WebkitClipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% -1%, 0% -1%)",
            }}
          ></div>
          <div
            style={{
              //height: this.state.device==='mobile'? '140vh': '90vh',
              //marginTop: this.state.device==='mobile'? '-140vh': '-90vh',
              backgroundColor: colors.lightgrey,
              //WebkitClipPath:this.state.path,
              display: "flex",
              flexDirection:
                this.props.store.device === "mobile" ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={selectedStyles.index.lowerSection.textContainer}>
              <div style={selectedStyles.index.lowerSection.innerTextContainer}>
                <h3 style={selectedStyles.index.lowerSection.heading}>
                  {financeProductData.section3Heading}
                </h3>
                <h2 style={selectedStyles.index.lowerSection.subHeading}>
                  {financeProductData.section3SubHeading1}
                </h2>
                <div style={selectedStyles.index.lowerSection.text}>
                  <ReactMarkdown
                    escapeHtml={false}
                    parserOptions={{ commonmark: true }}
                    source={financeProductData.section3Text1}
                  />
                </div>
                <h2 style={selectedStyles.index.lowerSection.subHeading}>
                  {financeProductData.section3SubHeading2}
                </h2>
                <div style={selectedStyles.index.lowerSection.text}>
                  <ReactMarkdown
                    escapeHtml={false}
                    parserOptions={{ commonmark: true }}
                    source={financeProductData.section3Text2}
                  />
                </div>
                <h2 style={selectedStyles.index.lowerSection.subHeading}>
                  {financeProductData.section3SubHeading3}
                </h2>
                <div style={selectedStyles.index.lowerSection.text}>
                  <ReactMarkdown
                    escapeHtml={false}
                    parserOptions={{ commonmark: true }}
                    source={financeProductData.section3Text3}
                  />
                </div>
              </div>
            </div>
            <div style={selectedStyles.index.lowerSection.imgContainer}>
              <img
                src={financeProductData.section3Image}
                style={selectedStyles.index.lowerSection.img}
                alt="some graphic"
              />
            </div>
          </div>
          <div
            style={{
              height: "10vw",
              width: "100%",
              backgroundColor: colors.white,
              clipPath: "polygon(0% 100%, 7% 50%, 93% 50%, 100% 0%, 101% 100%)",
              WebkitClipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% 0%, 101% 100%)",
            }}
          ></div>
          <div style={selectedStyles.index.lowerSection.lowerBackground}></div>

          <div
            style={{
              padding: "0vw 5vw",
              //height: "130vh",
              backgroundColor: colors.white,
            }}
          >
            <div style={selectedStyles.index.section4.container}>
            {this.props.store.device==='mobile'&&
            financeProductData.section4ctaText&&
                  <div style={selectedStyles.index.section4.buttonContainer}>
                  <CalendlyButton
                  styles={selectedStyles.index.upperSection.cta}
                  url={
    "https://calendly.com/lt-finance-platform/lt-finance-platform-exploration"                  }
                >
                  {financeProductData.section4ctaText}
                </CalendlyButton>
                </div>}
              <div style={selectedStyles.index.section4.imgContainer}>
                <img
                  src={financeProductData.section4Image}
                  style={selectedStyles.index.section4.img}
                  alt="some graphic"
                />
              </div>
              <div style={selectedStyles.index.section4.textContainer}>
                <div style={selectedStyles.index.section4.innerTextContainer}>
                  <h3 style={selectedStyles.index.section4.heading}>
                    {financeProductData.section4Heading}
                  </h3>
                  <h2 style={selectedStyles.index.section4.subHeading}>
                    {financeProductData.section4SubHeading}
                  </h2>
                  <div style={selectedStyles.index.section4.text}>
                    <ReactMarkdown
                      escapeHtml={false}
                      parserOptions={{ commonmark: true }}
                      source={financeProductData.section4Text}
                    />
                  </div>
                </div>
                {this.props.store.device==='desktop'&&
            financeProductData.section4ctaText&&
                  <div style={selectedStyles.index.section4.buttonContainer}>
                  <CalendlyButton
                  styles={selectedStyles.index.upperSection.cta}
                  url={calendlyLink}
                >
                  {financeProductData.section4ctaText}
                </CalendlyButton>
                </div>}
              </div>
              
            </div>
          </div>

          <ContactBanner backgroundColor={colors.white} />
          <Newsletter />
        </div>
      </Layout>
    )
  }
}

export default inject("store")(observer(IndexPage))
